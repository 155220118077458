<template>
  <v-container class="content">
    <v-row no-gutters>
      <v-col align="center">
        <h1 class="mb-8">Orders</h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col align="right">
        <v-btn color="primary" :to="{ name: 'adminOrderCreate' }" dark>
          Create
          <v-icon right> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="10">
          <v-data-table
            :elevation="15"
            :headers="headers"
            :items="data"
            :server-items-length="totalElements"
            :options.sync="pageable"
            @update:options="fetchData"
            :footer-props="footerProps"
            class="elevation-1"
          >
            <template v-slot:item.createdDate="{ item }">
              <div>
                {{ new Date(item.createdDate).toLocaleString() }}
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-chip
                color="blue accent-2"
                dark
                :to="{ name: 'adminOrderView', params: { id: item.id } }"
              >
                View
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  components: {},
  name: "AdminOrdersView",
  metaInfo: {
    title: "Orders",
  },
  data() {
    return {
      data: [],
      totalElements: 0,
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },
        { text: "Create Date", value: "createdDate" },
        { text: "Company", value: "company.name" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      pageable: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
      },
      footerProps: {
        "items-per-page-options": [5, 10, 15, 25, 50, 100],
      },
    };
  },
  methods: {
    fetchData() {
      let queryParams = {
        page: this.pageable.page - 1,
        size: this.pageable.itemsPerPage,
      };
      if (this.pageable.sortBy.length) {
        let direction = this.pageable.sortDesc[0] ? ",desc" : ",asc";
        queryParams.sort = this.pageable.sortBy[0] + direction;
      }
      axios
        .get("/api/admin/order", {
          params: queryParams,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        })
        .then((response) => {
          this.data = response.data.content;
          this.totalElements = response.data.totalElements;
        });
    },
  },
};
</script>

<style scoped>
</style>
